/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueResource from 'vue-resource'
import moment from 'moment'
import momenttimezone from 'moment-timezone'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueResource)
Vue.use(moment)
Vue.use(momenttimezone)

new Vue({
    el: '#mainNav', // where <div id="app"> in your DOM contains the Vue template
    name: 'Main Navigation',
    data: {},
    mounted: function() {
        // Navbar shrink on scroll
        this.$nextTick(function(){
            window.addEventListener("scroll", function(){
                var navbar = document.getElementById("mainNav");
                var nav_classes = navbar.classList;
                    if(document.documentElement.scrollTop >= 150) {
                        if (nav_classes.contains("navbar-shrink") === false) {
                            nav_classes.toggle("navbar-shrink");
                        }
                }
                else {
                    if (nav_classes.contains("navbar-shrink") === true) {
                        nav_classes.toggle("navbar-shrink");
                    }
                }
            })
        })
    }
});